import React from 'react'
import InspectionDamageCell from './components/InspectionDamageCell'
import InputField from './components/InputField'
import CASplitFilter from './components/filters/splitFilter'
import CostDropdownField from './components/CostDropdownField'
import InspectionMedia from '../../components/inspection-media'
import SearchDropdownFilter from '../../components/react-table-column-filters/SearchDropdownFilter'
import DataTableMenu from '../../components/data-table-menu'

const GenerateDamageCheckbox = ({
  value,
  updateInspectionValue,
  index,
  hasPermission,
  isDamageApproved
}) => {
  return (
    <>
      <input
        type="checkbox"
        name="damages_generate"
        disabled={!hasPermission || isDamageApproved}
        style={{
          width: '14px',
          cursor: !hasPermission ? 'not-allowed' : 'pointer'
        }}
        onChange={e => {
          updateInspectionValue(index, e.target.checked, 'damages_generate')
        }}
        checked={value}
      />
    </>
  )
}

export const headerData = ({
  isPhotoLoading,
  setIsPhotoLoading,
  toggleModal,
  updateInspectionValue,
  hasPermission,
  getRowOption,
  updateCustomResidentValues
}) => [
  {
    Header: '',
    hideHeader: false,
    id: 'unit_details',
    columns: [
      {
        Header: 'Building',
        accessor: 'building',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 90,
        Cell: row => {
          return <InspectionDamageCell row={row} />
        }
      },
      {
        Header: 'Unit',
        accessor: 'unit',
        textAlign: 'center',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 80,
        Cell: row => {
          return <InspectionDamageCell row={row} />
        }
      },
      {
        Header: 'Space',
        accessor: 'space',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 240,
        Cell: row => {
          return <InspectionDamageCell row={row} />
        }
      },
      {
        Header: 'Item',
        accessor: 'item',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 150,
        Cell: row => {
          return <InspectionDamageCell row={row} />
        }
      },
      {
        Header: 'Observation',
        accessor: 'observation',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 320,
        //minWidth: 180,
        Cell: row => {
          return <InspectionDamageCell row={row} />
        }
      },
      // {
      //   Header: 'Charge Type',
      //   accessor: 'is_adhoc',
      //   textAlign: 'left',
      //   disableFilters: true,
      //   disableSortBy: true,
      //   width: 150,
      //   Cell: row => {
      //     return <InspectionDamageCell row={row} />
      //   }
      // },
      {
        Header: 'Generate Damage?',
        accessor: 'damages_generate',
        textAlign: 'center',
        disableFilters: true,
        disableSortBy: true,
        width: 150,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original, index } = currentRow || {}
          const { damages_generate, inspection_status } = original || {}
          return (
            <div>
              <GenerateDamageCheckbox
                value={damages_generate}
                isDamageApproved={inspection_status === 'Damages Approved'}
                updateInspectionValue={updateInspectionValue}
                index={index}
                hasPermission={hasPermission}
              />
            </div>
          )
        }
      },
      {
        Header: 'Damage Charge',
        accessor: 'damage_cost',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 150,
        Cell: row => {
          return (
            <InputField
              row={row}
              updateInspectionValue={updateInspectionValue}
              type="damage_cost"
              hasPermission={hasPermission}
            />
          )
        }
      },
      {
        Header: 'Damage Code',
        accessor: 'damage_code',
        textAlign: 'left',
        // Filter: SearchDropdownFilter,
        // filter: 'customEquals',
        width: 130,
        Cell: row => (
          <InputField
            row={row}
            updateInspectionValue={updateInspectionValue}
            type="damage_code"
            hasPermission={hasPermission}
          />
        )
      },
      {
        Header: 'CA Split Type',
        accessor: 'ca_split',
        Filter: CASplitFilter,
        textAlign: 'left',
        width: 150,
        Cell: row => {
          const { cell, data } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          const { ca_split, damage_cost, id, splits, inspection_status } =
            original || {}
          return (
            <div className="single-damage" style={{ display: 'flex' }}>
              <CostDropdownField row={row} hasPermission={hasPermission} />
              {hasPermission &&
              inspection_status !== 'Damages Approved' &&
              ca_split == '3' ? (
                <button
                  className="button is-success"
                  onClick={e => {
                    e.preventDefault()
                    if (
                      !damage_cost ||
                      damage_cost == 0 ||
                      damage_cost === ''
                    ) {
                      alert(
                        'To proceed with resident custom cost, please insert a damage cost first.'
                      )
                    } else {
                      updateCustomResidentValues(damage_cost, data, id, splits)
                    }
                  }}
                  title="Add Custom Split Charges"
                  disabled={
                    inspection_status === 'Damages Approved' || !hasPermission
                  }
                  style={{
                    width: '35px',
                    marginLeft: '6px',
                    marginTop: '2px',
                    cursor: 'pointer',
                    height: '30px'
                  }}>
                  $$
                </button>
              ) : null}
            </div>
          )
        }
      },
      {
        Header: 'Photos',
        accessor: 'line_media',
        textAlign: 'center',
        disableFilters: true,
        disableSortBy: true,
        width: 80,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original } = currentRow || {}
          return (
            <div className="cell-item">
              {original?.line_media?.length && (
                <InspectionMedia
                  setIsPhotoLoading={setIsPhotoLoading}
                  isPhotoLoading={isPhotoLoading}
                  customClass="link"
                  onToggle={() => toggleModal(original)}
                  row={original}
                />
              )}
            </div>
          )
        }
      },
      {
        Header: 'Resident',
        accessor: 'resident',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 150,
        Cell: row => {
          return <InspectionDamageCell row={row} />
        }
      },
      {
        Header: 'Status',
        accessor: 'simplified_damage_required',
        textAlign: 'left',
        Filter: SearchDropdownFilter,
        filter: 'customEquals',
        width: 120,
        Cell: row => {
          return <InspectionDamageCell row={row} />
        }
      },
      {
        Header: '',
        accessor: 'inspection_id1',
        textAlign: 'center',
        disableFilters: true,
        disableSortBy: true,
        width: 40,
        Cell: row => {
          const { cell } = row || {}
          const { row: currentRow } = cell || {}
          const { original, index } = currentRow || {}
          const currentOptions = getRowOption(original, index)
          const { inspection_status, is_ca, ca_split } = original || {}
          return (
            <div className="menu-item">
              {hasPermission &&
                (inspection_status !== 'Damages Approved' ||
                  (is_ca && ca_split !== null)) && (
                  <DataTableMenu
                    options={currentOptions || []}
                    row={original}
                  />
                )}
            </div>
          )
        }
      }
    ]
  }
]
