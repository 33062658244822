import Service from '../service'
import LOGOUT from './auth'
import moment from 'moment'
import {
  REQUEST_FORM_ITEM,
  REQUEST_FORM_ITEM_SUCCESS,
  REQUEST_FORM_ITEM_FAIL,
  RESET_FORM_ITEM,
  REQUEST_APPROVED_INSPECTION_LIST,
  REQUEST_APPROVED_INSPECTION_LIST_SUCCESS,
  REQUEST_APPROVED_INSPECTION_LIST_FAIL,
  REQUEST_SINGLE_DAMAGE_INSPECTION,
  REQUEST_SINGLE_DAMAGE_INSPECTION_SUCCESS,
  REQUEST_SINGLE_DAMAGE_INSPECTION_FAIL,
  RESET_DAMAGE_INSPECTION,
  REQUEST_ADHOC_UNIT_SPACE,
  REQUEST_ADHOC_UNIT_SPACE_SUCCESS,
  REQUEST_ADHOC_UNIT_SPACE_FAIL
} from './damagesTypes'
import { formatDate } from '../helpers'

/**
 * Damages data module
 * @redux
 * @reduxActionScope damages
 * @module damages
 */

const initialState = {
  singleForm: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  approvedInspectionsList: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  },
  singleDamageInspection: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: [],
    allInspectionSpaces: []
  },
  adhocUnitSpaces: {
    hasMadeInitialRequest: false,
    isRequesting: false,
    isError: false,
    data: []
  }
}

/**
 * Inspect board reducer
 * @redux
 * @reduxReducer
 */
export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FORM_ITEM:
      return {
        ...state,
        singleForm: {
          ...state.singleForm,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false,
          data: []
        }
      }
    case REQUEST_FORM_ITEM_SUCCESS: {
      const { data } = action || {}
      return {
        ...state,
        singleForm: {
          ...state.singleForm,
          isRequesting: false,
          isError: false,
          data
        }
      }
    }
    case REQUEST_FORM_ITEM_FAIL: {
      const { data } = action || {}
      return {
        ...state,
        singleForm: {
          ...state.singleForm,
          isRequesting: false,
          isError: true,
          data
        }
      }
    }
    case REQUEST_APPROVED_INSPECTION_LIST:
      return {
        ...state,
        approvedInspectionsList: {
          ...state.approvedInspectionsList,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false,
          data: []
        }
      }
    case REQUEST_APPROVED_INSPECTION_LIST_SUCCESS: {
      const { data } = action || {}
      return {
        ...state,
        approvedInspectionsList: {
          ...state.approvedInspectionsList,
          isRequesting: false,
          isError: false,
          data
        }
      }
    }
    case REQUEST_APPROVED_INSPECTION_LIST_FAIL: {
      return {
        ...state,
        approvedInspectionsList: {
          ...state.approvedInspectionsList,
          isRequesting: false,
          isError: true,
          data: []
        }
      }
    }
    case REQUEST_SINGLE_DAMAGE_INSPECTION:
      return {
        ...state,
        singleDamageInspection: {
          ...state.singleDamageInspection,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false,
          data: [],
          allInspectionSpaces: []
        }
      }
    case REQUEST_SINGLE_DAMAGE_INSPECTION_SUCCESS: {
      const { data, allInspectionSpaces } = action || {}
      return {
        ...state,
        singleDamageInspection: {
          ...state.singleDamageInspection,
          isRequesting: false,
          isError: false,
          data,
          allInspectionSpaces
        }
      }
    }
    case REQUEST_SINGLE_DAMAGE_INSPECTION_FAIL: {
      return {
        ...state,
        singleDamageInspection: {
          ...state.singleDamageInspection,
          isRequesting: false,
          isError: true,
          data: [],
          allInspectionSpaces: []
        }
      }
    }
    case RESET_FORM_ITEM:
      return {
        ...state,
        singleForm: {
          hasMadeInitialRequest: false,
          isRequesting: false,
          isError: false,
          data: []
        }
      }
    case RESET_DAMAGE_INSPECTION:
      return {
        ...state,
        singleDamageInspection: {
          hasMadeInitialRequest: false,
          isRequesting: false,
          isError: false,
          data: [],
          allInspectionSpaces: []
        }
      }
    case REQUEST_ADHOC_UNIT_SPACE:
      return {
        ...state,
        adhocUnitSpaces: {
          ...state.adhocUnitSpaces,
          hasMadeInitialRequest: true,
          isRequesting: true,
          isError: false,
          data: []
        }
      }
    case REQUEST_ADHOC_UNIT_SPACE_SUCCESS: {
      const { data } = action || {}
      return {
        ...state,
        adhocUnitSpaces: {
          ...state.adhocUnitSpaces,
          isRequesting: false,
          isError: false,
          data
        }
      }
    }
    case REQUEST_ADHOC_UNIT_SPACE_FAIL: {
      const { data } = action || {}
      return {
        ...state,
        adhocUnitSpaces: {
          ...state.adhocUnitSpaces,
          isRequesting: false,
          isError: true,
          data
        }
      }
    }
    case LOGOUT:
      return initialState
    default:
      return state
  }
}

export const updateSingleInspectionData = data => {
  return dispatch => {
    dispatch({
      type: REQUEST_SINGLE_DAMAGE_INSPECTION_SUCCESS,
      data: data || []
    })
  }
}

const constructDataForSingleInspection = customData => {
  let data = []
  if (customData?.length) {
    data = [customData[0]]
  }
  const constructData = []
  const loopFormData = data.map(formData => {
    const spaceData = formData?.spaces.map(selectedSpace => {
      const itemData = selectedSpace?.items?.map(selectedItem => {
        if (selectedItem !== null) {
          const observationata = selectedItem?.observations?.map(
            selectedObservation => {
              if (selectedItem !== null) {
                const is_common = selectedSpace?.space_type
                  ? selectedSpace?.space_type?.some(
                      type => type?.toLowerCase() === 'common'
                    )
                  : false
                constructData.push({
                  form_name: formData.name,
                  space: selectedSpace.name,
                  is_ca: is_common,
                  item: selectedItem.name,
                  observation: selectedObservation.name,
                  damage_code: selectedObservation.damage_code,
                  damage_cost: selectedObservation.damage_cost,
                  form_observation_id: selectedObservation.form_observation_id,
                  damages_generate:
                    selectedObservation.damages_generate == 'true'
                      ? true
                      : false,
                  ca_split_type: selectedObservation?.ca_split_type
                    ? selectedObservation?.ca_split_type
                    : is_common
                    ? '1'
                    : null
                })
              }
            }
          )
        }
      })
    })
    return formData
  })
  return constructData
}

export const getSingleForm = (pdbid, context, form_id, onSuccess, onError) => {
  return dispatch => {
    dispatch({
      type: REQUEST_FORM_ITEM
    })
    Service.getSingleForm(pdbid, context, form_id)
      .then(async res => {
        if (res.ok) {
          const json = await res.json()
          const constructData = constructDataForSingleInspection(json)
          dispatch({
            type: REQUEST_FORM_ITEM_SUCCESS,
            data: constructData || []
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        dispatch({
          type: REQUEST_FORM_ITEM_FAIL,
          data: []
        })
        if (onError) {
          onError()
        }
      })
      .catch(error => {
        dispatch({
          type: REQUEST_FORM_ITEM_FAIL,
          data: []
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const saveDamageForm = ({
  pdbid,
  context,
  body,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.saveDamageForm(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

const constructApprovedInspectionExportListData = data => {
  let constructData = []
  const { inspection_lines } = data || {}
  const filterInspectionLines = (inspection_lines || []).map((insp, i) => {
    const { items, adhoc } = insp || {}
    const filterItems = (items || []).map(item_data => {
      const { observations, state } = item_data || {}
      if (observations !== null && observations.length !== 0) {
        const filterObservation = (observations || []).map(obser_data => {
          const is_common = insp?.space_type
            ? insp?.space_type?.some(type => type?.toLowerCase() === 'common')
            : false
          constructData.push({
            id: Math.floor(Math.random() * 100000) + 1,
            building: data.building,
            inspection_name: data.type,
            inspection_id: data.inspection_id,
            inspection_status: data.status,
            unit: data.unit,
            unit_id: data.unit_id,
            space: insp.location,
            unit_space: insp.unit_space,
            item: item_data.item,
            line_media: item_data.line_media,
            resident: insp.resident,
            line_observation_id: obser_data.line_observation_id,
            observation: obser_data.observation,
            damage_code: obser_data.damage_code?.trim(),
            damage_cost: obser_data.damage_cost,
            damage_required: obser_data.damage_required === null ? true : false,
            simplified_damage_required:
              obser_data.damage_required === null ? 'Required' : 'Not Required',
            damages_generate:
              obser_data.damages_generate == 'true' ? true : false,
            original_damages_generate: obser_data.damages_generate,
            ca_split: is_common
              ? obser_data?.ca_split
                ? obser_data?.ca_split
                : '1'
              : null,
            original_ca_split: splitsDecoded(
              is_common
                ? obser_data?.ca_split
                  ? obser_data?.ca_split
                  : '1'
                : null
            ),
            is_ca: is_common,
            unit_space_id: insp.unit_space_id,
            inspection_adhoc_damage_id: null,
            is_adhoc: false,
            splits: obser_data.splits || null,
            building_ext_id: data.building_ext_id || 'N/A',
            property_ext_id: data.property_ext_id || 'N/A',
            unit_ext_id: data.unit_ext_id || 'N/A',
            lease_ext_id: insp.lease_ext_id || 'N/A',
            tenant_ext_id: insp.tenant_ext_id || 'N/A',
            unit_space_ext_id: insp.unit_space_ext_id || 'N/A'
          })
        })
      }
    })
    const filterAdhoc = (adhoc || []).map(adhoc_data => {
      const is_common = insp?.space_type
        ? insp?.space_type?.some(type => type?.toLowerCase() === 'common')
        : false
      constructData.push({
        id: Math.floor(Math.random() * 100000) + 1,
        building: data.building,
        inspection_name: data.type,
        inspection_id: data.inspection_id,
        inspection_status: data.status,
        unit: data.unit,
        unit_id: data.unit_id,
        space: insp.unit_space,
        unit_space: insp.unit_space,
        item: 'Additional Damage Charge',
        line_media: adhoc_data.media,
        resident: insp.resident,
        line_observation_id: null,
        observation: adhoc_data.note,
        damage_code: adhoc_data.damage_code?.trim(),
        damage_cost: adhoc_data.damage_cost,
        damage_required: adhoc_data.status === null ? true : false,
        simplified_damage_required:
          adhoc_data.status === null ? 'Required' : 'Not Required',
        damages_generate: adhoc_data.damages_generate == 'true' ? true : false,
        original_damages_generate: adhoc_data.damages_generate,
        ca_split: is_common
          ? adhoc_data?.ca_split
            ? adhoc_data?.ca_split
            : '1'
          : null,
        original_ca_split: splitsDecoded(
          is_common ? (adhoc_data?.ca_split ? adhoc_data?.ca_split : '1') : null
        ),
        is_ca: is_common,
        unit_space_id: insp.unit_space_id,
        inspection_adhoc_damage_id: adhoc_data.inspection_adhoc_damage_id,
        is_adhoc: true,
        splits: adhoc_data.splits || null,
        building_ext_id: data.building_ext_id || 'N/A',
        property_ext_id: data.property_ext_id || 'N/A',
        unit_ext_id: data.unit_ext_id || 'N/A',
        lease_ext_id: insp.lease_ext_id || 'N/A',
        tenant_ext_id: insp.tenant_ext_id || 'N/A',
        unit_space_ext_id: insp.unit_space_ext_id || 'N/A'
      })
    })
  })
  return constructData
}

const checkAllStates = data => {
  let constructData = []
  const { inspection_lines = [] } = data
  ;(inspection_lines || []).forEach(insp => {
    const { items } = insp || {}
    ;(items || []).forEach(item_data => {
      constructData.push(item_data?.state)
    })
  })
  return (constructData || [])?.every(state => state?.toLowerCase() == 'good')
}

const constructApprovedInspectionListData = data => {
  const updateData = (data || []).map(data => {
    data.status = data?.status === 'Damages Approved' ? 'Approved' : 'Pending'
    data.inspection_date = formatDate(data.inspection_date)
    data.excel_export = constructApprovedInspectionExportListData(data)
    data.all_goods = checkAllStates(data)
    return data
  })
  return (updateData || [])?.filter(item => !item?.all_goods)
}

export const getApprovedInspectionList = (
  pdbid,
  context,
  onSuccess,
  onError
) => {
  return dispatch => {
    dispatch({
      type: REQUEST_APPROVED_INSPECTION_LIST
    })
    Service.getApprovedInspectionList(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const json = await res.json()
          const updateData = constructApprovedInspectionListData(json)
          dispatch({
            type: REQUEST_APPROVED_INSPECTION_LIST_SUCCESS,
            data: updateData || []
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        dispatch({
          type: REQUEST_APPROVED_INSPECTION_LIST_FAIL,
          data: []
        })
        if (onError) {
          onError()
        }
      })
      .catch(error => {
        dispatch({
          type: REQUEST_APPROVED_INSPECTION_LIST_FAIL,
          data: []
        })
        if (onError) {
          onError()
        }
      })
  }
}

export const changeDamageInspectionStatus = ({
  pdbid,
  context,
  body,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.changeDamageInspectionStatus(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

const splitsDecoded = val => {
  if (val == '1') {
    return 'All Residents'
  } else if (val == '2') {
    return 'Non-renewed Residents'
  } else if (val == '3') {
    return 'Custom'
  } else {
    return 'Select'
  }
}

const constructAllDamageInspectionSpaces = data => {
  let constructData = []
  ;(data || []).forEach(data => {
    const { inspection_lines } = data || {}
    ;(inspection_lines || []).forEach((insp, i) => {
      const { unit_space_id, unit_space, resident } = insp || {}
      if (unit_space_id && unit_space_id !== null) {
        constructData.push({
          unit_space_id,
          unit_space,
          resident
        })
      }
    })
  })
  return constructData
}

const constructSingleDamageInspectionData = data => {
  let constructData = []
  const filterData = (data || []).map(data => {
    const { inspection_lines } = data || {}
    const filterInspectionLines = (inspection_lines || []).map((insp, i) => {
      const { items, adhoc } = insp || {}
      const filterItems = (items || []).map(item_data => {
        const { observations } = item_data || {}
        if (observations !== null && observations.length !== 0) {
          const filterObservation = (observations || []).map(obser_data => {
            const is_common = insp?.space_type
              ? insp?.space_type?.some(type => type?.toLowerCase() === 'common')
              : false
            constructData.push({
              id: Math.floor(Math.random() * 100000) + 1,
              building: data.building,
              inspection_name: data.type,
              inspection_id: data.inspection_id,
              inspection_status: data.status,
              unit: data.unit,
              unit_id: data.unit_id,
              space: insp.location,
              unit_space: insp.unit_space,
              item: item_data.item,
              line_media: item_data.line_media,
              resident: insp.resident,
              line_observation_id: obser_data.line_observation_id,
              observation: obser_data.observation,
              damage_code: obser_data?.damage_code?.trim(),
              damage_cost: obser_data.damage_cost,
              damage_required:
                obser_data.damage_required === null ? true : false,
              simplified_damage_required:
                obser_data.damage_required === null
                  ? 'Required'
                  : 'Not Required',
              damages_generate:
                obser_data.damages_generate == 'true' ? true : false,
              original_damages_generate: obser_data.damages_generate,
              ca_split: is_common
                ? obser_data?.ca_split
                  ? obser_data?.ca_split
                  : '1'
                : null,
              original_ca_split: splitsDecoded(
                is_common
                  ? obser_data?.ca_split
                    ? obser_data?.ca_split
                    : '1'
                  : null
              ),
              is_ca: is_common,
              unit_space_id: insp.unit_space_id,
              inspection_adhoc_damage_id: null,
              is_adhoc: false,
              splits: obser_data.splits || null,
              building_ext_id: data.building_ext_id || 'N/A',
              property_ext_id: data.property_ext_id || 'N/A',
              unit_ext_id: data.unit_ext_id || 'N/A',
              lease_ext_id: insp.lease_ext_id || 'N/A',
              tenant_ext_id: insp.tenant_ext_id || 'N/A',
              unit_space_ext_id: insp.unit_space_ext_id || 'N/A'
            })
          })
        }
      })
      const filterAdhoc = (adhoc || []).map(adhoc_data => {
        const is_common = insp?.space_type
          ? insp?.space_type?.some(type => type?.toLowerCase() === 'common')
          : false
        constructData.push({
          id: Math.floor(Math.random() * 100000) + 1,
          building: data.building,
          inspection_name: data.type,
          inspection_id: data.inspection_id,
          inspection_status: data.status,
          unit: data.unit,
          unit_id: data.unit_id,
          space: insp.unit_space,
          unit_space: insp.unit_space,
          item: 'Additional Damage Charge',
          line_media: adhoc_data.media,
          resident: insp.resident,
          line_observation_id: null,
          observation: adhoc_data.note,
          damage_code: adhoc_data.damage_code?.trim(),
          damage_cost: adhoc_data.damage_cost,
          damage_required: adhoc_data.status === null ? true : false,
          simplified_damage_required:
            adhoc_data.status === null ? 'Required' : 'Not Required',
          damages_generate:
            adhoc_data.damages_generate == 'true' ? true : false,
          original_damages_generate: adhoc_data.damages_generate,
          ca_split: is_common
            ? adhoc_data?.ca_split
              ? adhoc_data?.ca_split
              : '1'
            : null,
          original_ca_split: splitsDecoded(
            is_common
              ? adhoc_data?.ca_split
                ? adhoc_data?.ca_split
                : '1'
              : null
          ),
          is_ca: is_common,
          unit_space_id: insp.unit_space_id,
          inspection_adhoc_damage_id: adhoc_data.inspection_adhoc_damage_id,
          is_adhoc: true,
          splits: adhoc_data.splits || null,
          building_ext_id: data.building_ext_id || 'N/A',
          property_ext_id: data.property_ext_id || 'N/A',
          unit_ext_id: data.unit_ext_id || 'N/A',
          lease_ext_id: insp.lease_ext_id || 'N/A',
          tenant_ext_id: insp.tenant_ext_id || 'N/A',
          unit_space_ext_id: insp.unit_space_ext_id || 'N/A'
        })
      })
    })
  })
  return constructData
}

export const getSingleDamageInspection = (
  pdbid,
  context,
  inspection_id,
  onSuccess,
  OnError
) => {
  return dispatch => {
    dispatch({
      type: REQUEST_SINGLE_DAMAGE_INSPECTION
    })
    Service.getSingleDamageInspection(pdbid, context, inspection_id)
      .then(async res => {
        if (res.ok) {
          const json = await res.json()
          const constructData = constructSingleDamageInspectionData(json)
          const constructAllSpacesData = constructAllDamageInspectionSpaces(
            json
          )
          dispatch({
            type: REQUEST_SINGLE_DAMAGE_INSPECTION_SUCCESS,
            data: constructData || [],
            allInspectionSpaces: constructAllSpacesData || []
          })
          if (onSuccess) {
            onSuccess(constructData || [])
          }
          return
        }
        dispatch({
          type: REQUEST_SINGLE_DAMAGE_INSPECTION_FAIL,
          data: [],
          allInspectionSpaces: []
        })
        if (OnError) {
          OnError()
        }
      })
      .catch(error => {
        dispatch({
          type: REQUEST_SINGLE_DAMAGE_INSPECTION_FAIL,
          data: [],
          allInspectionSpaces: []
        })
        if (OnError) {
          OnError()
        }
      })
  }
}

export const saveDamageSingleInspection = ({
  pdbid,
  context,
  body,
  inspection_id,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.saveDamageSingleInspection(pdbid, context, body, inspection_id)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(() => {
        if (onError) {
          onError()
        }
      })
  }
}

export const getAdhocUnitSpaces = (
  pdbid,
  context,
  unit_id,
  onSuccess,
  OnError
) => {
  return dispatch => {
    dispatch({
      type: REQUEST_ADHOC_UNIT_SPACE,
      data: []
    })
    Service.getAdhocUnitSpaces(pdbid, context)
      .then(async res => {
        if (res.ok) {
          const unitSpaces = await res.json()
          let data = []
          if (unitSpaces?.length) {
            if (unit_id) {
              data = unitSpaces
                ?.filter(space => space?.unit === unit_id)
                ?.map(space => ({
                  name: space?.unit_space,
                  value: space?.unit_space_id,
                  resident: space?.resident,
                  is_non_renewed: space?.lease_status === 'R' ? false : true,
                  unit_name: space?.unit,
                  unit_space_ext_id: space?.unit_space_ext_id,
                  lease_ext_id: space?.lease_ext_id,
                  tenant_ext_id: space?.tenant_ext_id
                }))
                ?.filter(space => space?.value !== null)
            } else {
              data = unitSpaces
                ?.map(space => ({
                  name: space?.unit_space,
                  value: space?.unit_space_id,
                  resident: space?.resident,
                  is_non_renewed: space?.lease_status === 'R' ? false : true,
                  unit_name: space?.unit,
                  unit_space_ext_id: space?.unit_space_ext_id,
                  lease_ext_id: space?.lease_ext_id,
                  tenant_ext_id: space?.tenant_ext_id
                }))
                ?.filter(space => space?.value !== null)
            }
          }
          dispatch({
            type: REQUEST_ADHOC_UNIT_SPACE_SUCCESS,
            data: data || []
          })
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        dispatch({
          type: REQUEST_ADHOC_UNIT_SPACE_FAIL,
          data: []
        })
        if (OnError) {
          OnError()
        }
      })
      .catch(error => {
        dispatch({
          type: REQUEST_ADHOC_UNIT_SPACE_FAIL,
          data: []
        })
        if (OnError) {
          OnError()
        }
      })
  }
}

export const saveAdhocDamage = (pdbid, context, body, onSuccess, onError) => {
  return dispatch => {
    Service.saveAdhocDamage(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(error => {
        if (onError) {
          onError(error)
        }
      })
  }
}

export const deleteAdhocDamage = ({
  pdbid,
  context,
  body,
  onSuccess,
  onError
}) => {
  return dispatch => {
    Service.deleteAdhocDamage(pdbid, context, body)
      .then(async res => {
        const { ok } = res || {}
        if (ok) {
          if (onSuccess) {
            onSuccess()
          }
          return
        }
        if (onError) {
          onError()
        }
      })
      .catch(error => {
        if (onError) {
          onError(error)
        }
      })
  }
}

export const resetSelectForm = () => {
  return dispatch => {
    dispatch({ type: RESET_FORM_ITEM })
  }
}

export const resetSingleDamageInspection = () => {
  return dispatch => {
    dispatch({ type: RESET_DAMAGE_INSPECTION })
  }
}
